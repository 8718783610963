<template>
  <div class="lianluo" style="height:100%">
    <img src="../../assets/huayang/star.jpg" alt="" style="width:100%">
    <div class="titleTop">热门企业</div>
    <van-swipe class="homebanner swipeImgs" :autoplay="3000" indicator-color="white" :show-indicators="false" >
        <van-swipe-item>
          <img src="../../assets/huayang/洛阳万众吉利热电厂.jpg"/>
          <div class="title">洛阳万众吉利热电厂</div>
        </van-swipe-item>
         <van-swipe-item>
          <img src="../../assets/huayang/洛阳润宝研磨材料有限公司.jpg"/>
          <div class="title">洛阳润宝研磨材料有限公司</div>
        </van-swipe-item>
         <van-swipe-item>
          <img src="../../assets/huayang/洛阳白马集团有限责任公司.jpg"/>
          <div class="title">洛阳白马集团有限责任公司</div>
        </van-swipe-item>
         <van-swipe-item>
          <img src="../../assets/huayang/洛阳市三诺化工有限公司.jpg"/>
          <div class="title">洛阳市三诺化工有限公司</div>
        </van-swipe-item>
         <van-swipe-item>
          <img src="../../assets/huayang/昊华气体有限公司.jpg"/>
          <div class="title">昊华气体有限公司</div>
        </van-swipe-item>
         <van-swipe-item>
          <img src="../../assets/huayang/河南金彭车业有限公司.jpg"/>
          <div class="title">河南金彭车业有限公司</div>
        </van-swipe-item>
         <van-swipe-item>
          <img src="../../assets/huayang/黎明化工研究院.jpg"/>
          <div class="title">黎明化工研究院</div>
        </van-swipe-item>
         <van-swipe-item>
          <img src="../../assets/huayang/洛阳宏兴新能化工有限公司.jpg"/>
          <div class="title">洛阳宏兴新能化工有限公司</div>
        </van-swipe-item>
         <van-swipe-item>
          <img src="../../assets/huayang/中国石油化工股份有限公司洛阳分公司.jpg"/>
          <div class="title">中国石油化工股份有限公司洛阳分公司</div>
        </van-swipe-item>
         <van-swipe-item>
          <img src="../../assets/huayang/神华国华孟津发电有限责任公司.jpg"/>
          <div class="title">神华国华孟津发电有限责任公司</div>
        </van-swipe-item>
      </van-swipe>
  </div>
</template>
<script>
import { WeGetAppletInfo } from "@/api/index";
export default {
  data() {
    return {
      active: 4,
      appInfo:{},
    };
  },
  mounted(){
    this.getAppInfo();
  },
  methods: {
    // 小程序展示数据
    getAppInfo() {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          this.appInfo = list.data;
        }
      });
    },
  },
};
</script>
<style scoped>
.titleTop {
  text-align: center;
  margin: 15px 0;
}
.swipeImgs img{
  width: 100%;
  height: 200px;
}
.swipeImgs .title{
  padding: 10px 0px;
  text-align: center;
}
.swipeImgs .van-swipe__indicators {
  display: none;
}
</style>